<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template>
      <v-stepper v-model="fase" vertical>
        <v-stepper-step :complete="fase > 1" step="1">
          Upload File
          <small>Files format: xls, csv , xlsx</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-file-input
            v-model="file"
            accept=".xls,.csv,.xlsx"
            show-size
            truncate-length="15"
            label="Select File"
          ></v-file-input>
          <div class="d-flex">
            <v-select
              outlined
              dense
              class="mx-1"
              :items="yearShow"
              v-model="year"
              label="Year"
            ></v-select>
            <v-select
              outlined
              item-text="name"
              item-value="value"
              dense
              :items="monthShow"
              v-model="month"
              label="Month"
            ></v-select>
          </div>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading || file == null || year == null || month == null"
            @click="firstStep"
          >
            Next
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="fase > 2" step="2">
          Preview
          <small>Select files to match</small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <template>
            <div class="d-flex">
              <strong>Total:</strong>
              <div class="ml-1">
                {{ previewResult ? previewResult.total : 0 }}
              </div>
            </div>

            <v-divider></v-divider>
            <template>
              <v-card>
                <v-card-title>
                  Payments Found
                  {{ previewResult ? `(${previewResult.totalFound})` : "" }}
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchFP"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :items="found" :search="searchFP">
                  <template v-slot:[`item.billFromDate`]="{ item }">
                    <div class="cursor-pointer">
                      {{ formatDate(item.billFromDate) }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </template>
            <template>
              <v-card>
                <v-card-title>
                  Payments Not Found
                  {{ previewResult ? `(${previewResult.totalNoFound})` : "" }}
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchNFP"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :items="noFound" :search="searchNFP">
                  <template v-slot:[`item.billFromDate`]="{ item }">
                    <div class="cursor-pointer">
                      {{ formatDate(item.billFromDate) }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </template>
          </template>

          <v-btn color="error" class="mr-1 mt-2" :disabled="loading" @click="resetAll">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="mt-2"
            :loading="loading"
            :disabled="loading"
            @click="secondStep"
          >
            Confirm
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="3" :complete="fase == 3"> Import Results </v-stepper-step>
        <v-stepper-content step="3">
          <template>
            <div class="d-flex">
              <strong>Total:</strong>
              <div class="ml-1">
                {{ lastResult ? lastResult.total : 0 }}
              </div>
            </div>

            <v-divider></v-divider>
            <template>
              <v-card>
                <v-card-title>
                  Payments Found
                  {{ lastResult ? `(${lastResult.totalFound})` : "" }}
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchF"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :items="found" :search="searchF">
                  <template v-slot:[`item.billFromDate`]="{ item }">
                    <div class="cursor-pointer">
                      {{ formatDate(item.billFromDate) }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </template>
            <template>
              <v-card>
                <v-card-title>
                  Payments Not Found
                  {{ lastResult ? `(${lastResult.totalNoFound})` : "" }}
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchNF"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :items="noFound" :search="searchNF">
                  <template v-slot:[`item.billFromDate`]="{ item }">
                    <div class="cursor-pointer">
                      {{ formatDate(item.billFromDate) }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </template>
          </template>
          <v-btn class="mt-2" color="primary" @click="resetAll"
            ><v-icon class="mr-1">mdi-cloud-upload</v-icon> import another
          </v-btn>
          <v-btn class="mx-2 mt-2" color="success" @click="gotologs">View Log</v-btn>
          <v-btn class="mt-2" color="warning" @click="backclients">
            <v-icon>mdi-exit-to-app</v-icon> Exit
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </template>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import cleanObject from "@/utils/cleanObject";
import { notifyError } from "@/components/Notification";
import { mapActions, mapState } from "vuex";
import months from "@/utils/enums/months";
import moment from "moment";
import { find } from "lodash";
export default {
  name: "import-payment",
  data() {
    return {
      loading: false,
      searchFP: null,
      searchNFP: null,
      searchF: null,
      searchNF: null,
      month: null,
      year: null,
      firstData: null,
      lastResult: null,
      previewResult: null,
      uuidpay: null,
      configResult: null,
      file: null,
      fase: 1,
      found: [],
      noFound: [],
      headers: [
        { text: "Name", width: 300, value: "clientName", sortable: false },
        { text: "Policy Number", width: 100, value: "policyNumber", sortable: false },
        {
          text: "Commission",
          width: 100,
          align: "center",
          value: "commission",
          sortable: false,
        },
        { text: "Bill From Date", width: 100, value: "billFromDate", sortable: false },
        { text: "Month", width: 100, value: "monthOfBillFromDate", sortable: false },
        { text: "Coverage Type", width: 100, value: "coverageType", sortable: false },
        { text: "Lives", width: 100, value: "lives", sortable: false },
        { text: "Not Found Motive", width: 100, value: "notFoundMotive", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "companies"]),
    monthShow() {
      return months;
    },

    yearShow() {
      const actualyear = moment();
      let years = [actualyear.add(-1, "year").format("YYYY")];
      for (let index = 0; index < 2; index++) {
        const yar = moment();
        const element = yar.add(index, "year");
        years.push(element.format("YYYY"));
      }
      return years;
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    getData(value) {
      return this.firstData[value];
    },
    getMonth(val) {
      let temp = this.firstData[val];
      const x = this.monthShow.find((m) => m.index == temp);

      if (x) {
        return x.name;
      }
      return "Invalid Month";
    },
    getCurrentMonth(val) {
      const x = this.monthShow.find((m) => m.abbreviation == val);
      if (x) {
        return x.name;
      }
      return "Invalid Month";
    },
    getYear(val) {
      const x = this.yearShow.find((m) => m == val);
      if (x) {
        return x;
      }
      return "Invalid Year";
    },

    getDataDob(value) {
      let temp = this.firstData[value];
      if (value == null || temp == undefined) {
        return "Invalid date";
      }
      temp = temp.toString();
      if (
        temp.includes("T") &&
        temp.includes(":") &&
        temp.includes("-") &&
        temp.includes("Z")
      ) {
        const dat = temp.split("T")[0];
        const d = dat.split("-")[2];
        const m = dat.split("-")[1];
        const y = dat.split("-")[0];
        return `${m}/${d}/${y}`;
      }

      return "Invalid date";
    },

    getCompanySelect(value) {
      return this.companies.find((c) => c.id == value)?.name;
    },
    firstStep() {
      this.loading = true;
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);
      formDatafile.append("month", this.month);
      formDatafile.append("year", this.year);

      getAPI
        .post("/payment/importPayments?preview=true", formDatafile)
        .then((res) => {
          this.previewResult = res.data;
          this.found = res.data.payLogsfOUND;
          this.noFound = res.data.payLogsnOTfOUND;
          this.loading = false;
          this.fase = 2;
          this.uuidpay = res.data.task;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    secondStep() {
      this.loading = true;
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);
      formDatafile.append("month", this.month);
      formDatafile.append("year", this.year);

      getAPI
        .post("/payment/importPayments", formDatafile)
        .then((res) => {
          this.lastResult = res.data;

          this.found = res.data.payLogsfOUND;
          this.noFound = res.data.payLogsnOTfOUND;
          this.loading = false;
          this.fase = 3;
          this.uuidpay = res.data.task;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    backclients() {
      this.$router.push("/healths");
    },
    gotologs() {
      localStorage.setItem("logpayment", this.uuidpay);
      this.$router.push("/payments");
    },

    resetAll() {
      this.file = null;
      this.month = null;
      this.year = null;
      this.loading = false;
      this.fase = 1;

      this.found = [];
      this.noFound = [];
      this.firstData = null;
      this.lastResult = null;
      this.previewResult = null;
      this.configResult = null;
    },

    formatDate(date) {
      if (date) {
        const t = date.split("T")[0];
      const d = moment(t).format("MM/DD/YYYY");
      return d;
      }
      return '-'
     
    },
  },
  mounted() {
    this.actListCompaniesNom();
  },
};
</script>
<style lang="scss" scoped>
.table-result {
  width: 300px !important;
}
</style>
